html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App{
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  width: auto;
  position: relative;
  min-height: 100%;
  box-shadow: 0 0 20px #0000000d;
}

.no-style {
  background-color: initial;
  box-shadow: none;
  background-color: #fff;
  width: auto;
  overflow: hidden; /* 스크롤바 제거 */
}
.about-site-style{
  max-width: 1280px;
  margin: 0 auto;
  background-color: #fff;
  width: auto;
  position: relative;
  min-height: 100%;
  box-shadow: 0 0 30px #0000000d;
  overflow: hidden;
}

.search-bar{
  margin: 0 auto;
  max-width: 480px;
  box-sizing: border-box;
  position: fixed; 
  top: 0; /* 화면 상단에 고정 */
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ccc; 
  z-index: 1000; /* 다른 요소들보다 위에 위치 */
}

.search-bar-text-input{
  outline: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background-color: #ccc;
  display: block;
  width: 350px;
  padding-left: 20px;
  box-sizing: border-box;
  border-radius: 18px;
  height: 36px;
  font-weight: 500;
  padding-right: 15px;
  font-size: 13px;
}
.search-bar-logo{
  width: 50px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}
.search-bar-logo span{
  font-family: "Jua", sans-serif;
  font-weight: 400;
  font-size: 30px;
  user-select: none; 
}
.submit-search{
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
.submit-search-img{
  width: 100%;
  height: 100%;
  object-fit: contain; 
  border-radius: 50%; 
}
.under-bar {
  max-width: 480px;
  width: 100%; /* 너비를 100%로 설정하여 화면의 너비에 맞추되, max-width로 최대 크기 제한 */
  left: 50%;
  transform: translateX(-50%);
  position: fixed; /* 고정 위치로 설정 */
  bottom: 0; /* 화면 하단에 고정 */
  height: 48px;
  background: #fff; /* 배경색 */
  border-top: 1px solid #ccc; /* 상단 경계선 */
  display: flex;
  align-items: center;
  justify-content: center; /* 내용 중앙 정렬 */
  z-index: 1000; /* 다른 요소들보다 위에 위치 */
}
.under-bar-icon{
  width: 120px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center; 
}
.under-bar-img{
  width: 30px;
  height: 28px;
}


.wrapper{
  display: flex;
  flex-direction: column;
}

.store-type{
  margin-top: 48px;
  max-width: 480px;
  height: 58px;
  background: #fff;
  border-bottom: 1px solid #ccc; 
  display: flex;
  flex-direction: row;
}

.store-type-icon {
  width: 37px; 
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left:20px;
  margin-top:5px;
  position: relative;
}
.store-type-icon span{
  font-size: 12px;
  color: #222222; 
  user-select: none; 
}

.store-type-img {
  width: 90%;
  height: 90%;
  object-fit: contain; 
  border-radius: 50%; 
  border: 1px solid #ccc;
}

.store-type-icon.selected .store-type-img {
  border: 2px solid rgba(0, 162, 255, 0.685); 
  border-radius: 50%; 
}

.store-type-icon.selected span {
  font-weight: 650;
  color: rgba(0, 162, 255, 0.685); 
}

.store-type-icon .close-btn {
  position: absolute;
  top: -2px; /* 아이콘의 오른쪽 상단 모서리에 위치 */
  right: -2px; /* 아이콘의 오른쪽 상단 모서리에 위치 */
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 50%;
  width: 15px; /* 버튼의 너비 */
  height: 15px; /* 버튼의 높이 */
  font-size: 10px; /* 버튼의 폰트 크기 */
  line-height: 14px; /* 버튼의 높이와 동일하게 설정하여 텍스트 수직 정렬 */
  text-align: center; /* 텍스트 수평 중앙 정렬 */
  display: none;
  cursor: pointer;
  padding: 0; /* 패딩 제거 */
}

.store-type-icon.selected .close-btn {
  display: block; 
}

.event-type{
  max-width: 480px;
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #ccc; 
  display: flex;
  flex-direction: row;
  align-items: center;

}
.event-type-tab{
  width: 55px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #ccc;
  margin-left: 5px;
}

.event-type-tab span{
  font-size: 15px;
  font-weight: 600;
  color: #222222; 
  font-family: "Nanum Gothic", sans-serif;
  font-style: normal;
  user-select: none; 
}

.event-type-tab.selected {
  background-color: #007bff; 
  border: 1px solid #007bff; 
}

.event-type-tab.selected span {
  color: #ffffff; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 480px) {
  .App {
    max-width: 480px;
    margin: 0 auto;
    background-color: #fff;
    width: auto;
    position: relative;
    min-height: 100%;
    overflow-y: auto; /* 스크롤 허용 */
    box-shadow: 0 0 20px #0000000d;
  }
  
  .search-bar{
    width: 98%;
  }
  .search-bar-logo{
    height: 38px;
    padding-left: 1px;
    padding-right: 1px;
  }
  .search-bar-text-input{
    width: 320px;
  }
  }