.favorite-list {
  max-width: 480px;
  list-style: none; 
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0; 
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2열로 설정 */
  gap: 15px; /* 아이템 간의 간격 설정 */
  padding-bottom: 20px; 
}

.favorite-non{
  font-family: "Nanum Gothic", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  margin-top: 250px;
  user-select: none;
}


.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 45px;
  border-bottom: 2px solid #ccc; 
  flex-direction: row;
}

.tab-content{
  width: 173px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  font-family: "Nanum Gothic", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
}
.tab-content-active{
  font-weight: bold;
  border-bottom: 2px solid #000000; 
}

.pagination {
  display: flex;
  justify-content: center; /* 중앙 정렬 */
  align-items: center;
  gap: 10px; /* 버튼 간 간격 */
  max-width: 480px;
  margin-left: auto; /* 좌우 여백 자동 조정 */
  margin-right: auto;
}

.pagination button {
  border: none;
  background: none;
  font-family: "Nanum Gothic", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
}

.pagination button.active {
  font-weight: 900;
  text-decoration: underline;
}

/* .pagination-button{
  position: absolute;
  bottom: 75px;
  left: 180px;
  max-width: 480px;
}
.left-button{
  position: absolute;
  bottom: 75px;
  left: 99px;
  
}
.right-button{
  position: absolute;
  bottom: 75px;
  right: 105px;
} */

@media (min-width: 769px) and (max-width: 1440px) {
  .favorite-list {
    padding: 10px 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    overflow-y: auto;
    justify-content: center;
    gap: 10px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    padding-bottom: 70px;
    width: 100%;
  }

  .item, .favorite {
    max-width: 100%;
    height: auto;
  }

  .pagination-button {
    max-width: 480px;
  }

  .tab {
    max-width: 100%;
  }
}

@media (min-width: 482px) and (max-width: 769px) {
  .favorite-list {
    padding: 10px 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    overflow-y: auto;
    justify-content: center;
    gap: 10px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    padding-bottom: 70px;
    width: 100%;
  }

  .item, .favorite {
    max-width: 100%;
    height: auto;
  }

  .pagination-button {
    max-width: 480px;
  }

  .tab {
    max-width: 100%;
  }
}


@media (max-width: 480px) {
  .favorite-list {
    padding-left: 5px;
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    max-height: 100%; /* 가능한 최대 높이를 화면 크기와 조정 */
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center; /* 가로 중앙 정렬 */
    grid-auto-rows: min-content; /* 각 행의 높이를 콘텐츠에 맞춤 */
    grid-template-rows: auto 1fr auto; /* 첫 번째는 콘텐츠, 두 번째는 상품 리스트, 세 번째는 Pagination */
    justify-content: center;
    gap:10px;
 }

  .item, .favorite {
      max-width: 100%; /* 부모 div의 너비에 맞춤 */
      height: auto; /* 높이 자동 조정 */
  }

  
  .pagination {
    display: flex;
    justify-content: center; /* 버튼들을 가로로 중앙 정렬 */
    align-items: center;
    gap: 10px; /* 버튼 간 간격 */
    width: 100%; /* 가로 전체 너비 사용 */
    margin: 20px 0;
    padding-bottom: 50px; /* 위아래 공간 추가 */
 }
  .pagination-button{

   max-width: 480px;
  }

  .tab{
    max-width: 100%;
  }



}