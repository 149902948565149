.about-site-background{
    width: 100%; /* 부모의 width를 따라감 */
    height: 100vh; 
    overflow: hidden;
    position: relative;
}

.page-one {
    position: absolute;
    top:35%;
    font-size: 35px;
    font-weight: bold;
    color: #222222; 
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    user-select: none; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
 .page-notice{
  font-size: 30px;
 }
.pages-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: transform 0.6s ease-in-out;
}

.page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Nanum Gothic", sans-serif;
  font-size: 35px;
  color: #222;
}

.page-two {
  background-color: #ffffff;
}

.down-icon {
  position: absolute;
  bottom: 2%;
  left: 50%;
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: bounce 3s infinite, blink 3s infinite;
}

@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(10px);
  }
}

@keyframes blink {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

.pages-wrapper.slide-in-down {
  transform: translateY(-100%);
}

.pages-wrapper.slide-in-up {
  transform: translateY(100%);
}

@keyframes typing {
  from {
      width: 0;
  }
  to {
      width: 100%;
  }
}


@keyframes blink-caret {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: black;
  }
}

.about-site-index{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 35px;
    font-weight: bold;
    color: #222222; 
    font-family: "Nanum Gothic", sans-serif;
    left: 3%;
    user-select: none;
}


.vertical-line {
    margin-top : 80px;
    width: 5px;
    height:74vh;
    background-color: rgba(0, 0, 0, 0.63);
    position: absolute; 
    left: 10%; 
    top: 7%; 
    border-radius: 5px; 
}

.introduction{
    position: absolute;
    left: 12%;
    top:14%;
    display: flex;
    flex-direction: column;
    font-size: 25px;
    font-weight: bold;
    color: #222222; 
    font-family: "Nanum Gothic", sans-serif;
    user-select: none; 

}

.planning-intention-space{
    height: 15px;
}

.horizontal-line {
    border-top: 4px solid rgba(0, 0, 0, 0.699); 
    width: 95%;
    margin-top:2%;
    left: 0;
    border-radius: 10px;
}

.planning-intention-sub-item{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #222222; 
    font-family: "Nanum Gothic", sans-serif;
}
.index{
  width: 165px;
  box-sizing: border-box; 
  margin-left: 10px;
  margin-top: 5px;
}

.index-box {
  width: 190px;
  height: 390px;
  background-color: transparent;
  position: fixed;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  box-shadow: none;
  border-radius: 10px;
  font-family: "Nanum Gothic", sans-serif;
  user-select: none;
  font-size: 15px;
  font-weight: 600;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.index-box.left {
  left: 80px;
  right: unset;
  transform: translateY(-50%) scaleX(-1); /* 좌우반전 */
}

.index-box:hover {
  background-color: rgb(245, 245, 245); /* 마우스를 올렸을 때 배경색 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 추가 */
}
.index-box.left:hover .page-index-title {
  margin-right: 5px;
  margin-top: 15px;
  width: 165px;
  height: 15px;
  background-color: transparent; 
  text-indent: 0; /* 텍스트 보여주기 */
  transform: translateY(-50%) scaleX(-1); /* 좌우반전 */
}
.index-box.left:hover .page-index-element {
  margin-left: -5px;
  margin-top: 10px;
  width: 165px;
  height: 15px;
  background-color: transparent; 
  text-indent: 0; /* 텍스트 보여주기 */
  transform: translateY(-50%) scaleX(-1); /* 좌우반전 */
  
}


.index-box:hover .page-index-title  {
  margin-left: 5px;
  margin-top: 10px;
  width: 165px;
  height: 15px;
  background-color: transparent; 
  text-indent: 0; /* 텍스트 보여주기 */
}

.index-box:hover .page-index-element  {
  margin-left: 15px;
  margin-top: 5px;
  width: 165px;
  height: 15px;
  background-color: transparent; 
  text-indent: 0; /* 텍스트 보여주기 */
}



.page-index-title {
  height: 8px;
  width: 70px;
  background-color: rgba(0, 0, 0, 0.2); /* 기본적으로 얇은 막대 */
  margin-left: 5px;
  margin-top: 20px;
  border-radius: 5px;
  text-indent: -9999px; /* 텍스트 숨김 */
}

.page-index-element{
  height: 8px;
  width: 45px;
  background-color: rgba(0, 0, 0, 0.2); /* 기본적으로 얇은 막대 */
  margin-left: 5px;
  margin-top: 21px;
  border-radius: 5px;
  text-indent: -9999px; /* 텍스트 숨김 */
  font-size: 13px;
}
.title-div{
  width: 1265px;
  height: 100px;
  position: absolute;
  top: 0%;
  left: 1px;
}

.index.active .page-index-title,
.index.active .page-index-element {
  background-color: #007bff; /* 활성화된 막대의 색상 */
  text-decoration: underline; /* hover 시 밑줄 추가 */
}

.index-box.left:hover .page-index-title,
.index-box.left:hover .page-index-element,
.index-box:hover .page-index-title,
.index-box:hover .page-index-element {
  background-color: transparent; /* hover시 색상 없애기 */

}

.page-picture-area{
  position: absolute;
  left: 5%;
  top: 12%;
}

.page-explanation-area{
  position: absolute;
  left: 5%;
  bottom: 8%;
  font-size: 20px;
  font-weight: bold;
  color: #222222; 
  font-family: "Nanum Gothic", sans-serif;
  width: 1200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.normal-page{
  width: 1000px;
  height: 450px;
  margin-left: 60px;
  object-fit: contain; 
}


.login-page{
  width: 1000px;
  height: 450px;
  margin-left: 60px;
  object-fit: contain; 
}

.react-icon{
  width: 1000px;
  height: 400px;
  margin-left: 60px;
  object-fit: contain; 
}

.spring-boot-icon{
    width: 750px;
    height: 400px;
    margin-left: 175px;
    object-fit: contain; 
}
.selenuimWithJsoup-icon{
  width: 750px;
  height: 400px;
  margin-left: 175px;
  object-fit: contain; 
}
.queryDSL-logo{
  width: 750px;
  height: 400px;
  margin-left: 200px;
  object-fit: contain; 
}
.workflow{
  width: 750px;
  height: 400px;
  margin-left: 200px;
  object-fit: contain; 
}
.gatling-graph{
  width: 750px;
  height: 500px;
  margin-left: 200px;
  object-fit: contain; 
}
.page-twelve{
  position: absolute;
  top:40%;
  font-size: 35px;
  font-weight: bold;
  color: #222222; 
  font-family: "Nanum Gothic", sans-serif;
  user-select: none;
}
.page-explanation-area-long{
  position: absolute;
  left: 5%;
  top: 15%;
  font-size: 22px;
  font-weight: bold;
  color: #222222; 
  font-family: "Nanum Gothic", sans-serif;
  width: 1200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.page-explanation-title{
  font-size: 30px;
  font-weight: bold;
  color: #222222; 
  font-family: "Nanum Gothic", sans-serif;
}