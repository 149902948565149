.item-list {
    max-width: 480px;
    list-style: none; 
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0; 
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2열로 설정 */
    gap: 10px; /* 아이템 간의 간격 설정 */
    padding-bottom: 60px; 
    position: relative;
}

.item {
    max-width: 210px;
    width: 100%; /* 그리드 셀에 맞게 너비를 100%로 설정 */
    box-sizing: border-box;
    background-color: #ffffff; 
    height: 295px; 
    outline: 1px solid #00000041; 
    border-radius: 10px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative; 
}

.favorite{
    max-width: 210px;
    width: 100%; 
    box-sizing: border-box;
    background-color: #ffffff; 
    height: 295px; 
    outline: 3px solid #fffb00; 
    border-radius: 10px; 
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative; 
}

.badge-left.one{
    padding: 5px 10px; /* 좌우 여백을 설정 */
    background-color: rgba(0, 162, 255, 0.685); 
    position: absolute; 
    top: 8px;
    left: 9px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px; 
    height: 28px; 
}
.badge-left.dum {
    padding: 5px 10px; /* 좌우 여백을 설정 */
    background-color: rgba(255, 217, 0, 0.911); 
    position: absolute; 
    top: 8px;
    left: 9px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px; 
    height: 28px; 
}
.badge-left.two{
    padding: 5px 10px; /* 좌우 여백을 설정 */
    background-color: rgba(21, 255, 0, 0.685); 
    position: absolute; 
    top: 8px;
    left: 9px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px; 
    height: 28px; 
}
.badge-left.sale{
    padding: 5px 10px; /* 좌우 여백을 설정 */
    background-color: rgba(255, 145, 0, 0.938); 
    position: absolute; 
    top: 8px;
    left: 9px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 35px; 
    height: 28px; 
}
.event-classification{
    color: white;
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    user-select: none; 
}

.badge-right {
    width: 45px;
    height: 35px; 
    position: absolute; 
    top: 8px;
    right: 9px; 
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.convenience-img {
    max-width: 100%; /* 배지 너비에 맞게 이미지 크기 조정 */
    max-height: 100%; 
    object-fit: contain; /* 배지 높이에 맞게 이미지 크기 조정 */
    border-radius: 30px; /* 배지의 모서리 둥글게 */
}



.product-img {
    display: flex;

    height: 200px;
    align-items: center;
    overflow: hidden; /* 이미지가 컨테이너를 넘지 않도록 설정 */
    margin-top:12px;
    justify-content: center;
}
.product-img span{
    
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
}
.product-img img {
    width: 100%;       /* 부모 div의 너비에 맞춤 */
    height: 100%;      /* 부모 div의 높이에 맞춤 */
    object-fit: cover; /* 이미지가 컨테이너에 맞게 자르거나 조정되도록 설정 */
    display: block;    /* 이미지 아래의 여백 제거 */
}

.item-list::marker {
    display: none;
}

.product-name {
    width: 100%;              
    white-space: nowrap;    
    overflow: hidden;         
    text-overflow: ellipsis;  
    text-align: center; 
    margin-top: 17px;       
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: 550;
    user-select: none; 
}
.product-price {
    width: 100%;              
    white-space: nowrap;    
    overflow: hidden;         
    text-overflow: ellipsis;  
    text-align: center; 
    margin-top: 5px;       
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    user-select: none;
    padding-bottom: 10px; 
}

.product-price .bold {
    font-size: 20px;
    font-weight: bold; 
    user-select: none; 
}
.add-favorite {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 10px;
    right: 9px;
    background-image: url('/public/star-gray.png'); /* 기본 이미지 */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.add-favorite:hover {
    background-image: url('/public/star-full.png'); /* hover 시 이미지 */
}

.delete-favorite {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 10px;
    right: 9px;
    background-image: url('/public/star-full.png'); /* 기본 이미지 */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.delete-favorite:hover {
    background-image: url('/public/star-gray.png');
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    z-index: 2; 
    pointer-events: none; /* 오버레이가 클릭 이벤트를 차단하지 않음 */
}

.show-dum{
    width: 30px;
    height: 30px;
    position: absolute;
    top : 100px;
    align-items: center;
    justify-content: center;
    font-family: "Jua", sans-serif;
    font-weight: 400;
    font-size: 25px;
    user-select: none;
    color: black;
}


.close-dum{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 9px;
    right: 170px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Jua", sans-serif;
    font-weight: 400;
    font-size: 25px;
    user-select: none;
}

.close-dum:hover{
    background-color:rgba(255, 217, 0, 0.911);
    color: white;
}
.button_group {
    display: flex;
    flex-direction: column;
    position: fixed; /* 고정 위치 설정 */
    width: 40px;
    height: 90px;
    bottom: 90px;
    right: 720px;
    z-index: 1;
    border: none; 
    justify-content: space-between;
}
.move_start, .move_end{
    width: 40px;
    height: 40px;
    opacity: 0.7;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #00000041;
}

.move_start:hover, .move_end:hover {
    opacity: 1; 
}

.product-non{
    font-family: "Nanum Gothic", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 480px;
    margin-top: 250px;
    user-select: none;
  }
.loader{
    padding-bottom: 20px;
}

@media (min-width: 481px) and (max-width: 1440px) {
    .item-list {
        padding-left: 1px;
        padding-right: 1px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .item, .favorite {
        max-width: 100%; /* 부모 div의 너비에 맞춤 */
        height: auto; /* 높이 자동 조정 */
    }   
    .delete-favorite{
        background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 5px;
    height: 30px;
    position: absolute;
    right: 9px;
    width: 30px;
    }

    .add-favorite{
    background-image: url('/public/star-gray.png'); 
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 5px;
    height: 30px;
    position: absolute;
    right: 9px;
    width: 30px;
    }   
    
    .button_group {
        display: flex;
        flex-direction: column;
        position: fixed; /* 고정 위치 설정 */
        width: 40px;
        height: 90px;
        bottom: 90px;
        right: 10px;
        z-index: 1;
        border: none; 
        justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .item-list {
        padding-left: 1px;
        padding-right: 1px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .item, .favorite {
        max-width: 100%; /* 부모 div의 너비에 맞춤 */
        height: auto; /* 높이 자동 조정 */
    }
    .delete-favorite{
        background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 5px;
    height: 30px;
    position: absolute;
    right: 9px;
    width: 30px;
    }

    .add-favorite{
    background-image: url('/public/star-gray.png'); 
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 5px;
    height: 30px;
    position: absolute;
    right: 9px;
    width: 30px;
    }   

    .button_group {
        display: flex;
        flex-direction: column;
        position: fixed; /* 고정 위치 설정 */
        width: 40px;
        height: 90px;
        bottom: 90px;
        right: 10px;
        z-index: 1;
        border: none; 
        justify-content: space-between;
    }
}
